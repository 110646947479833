<template>
  <header>
    <div class="header"></div>
    <div class="text col-12 col-xl-8">
      <h1>Votre taxi à Les Mureaux et secteurs limitrophes</h1>
    </div>
    <h2>Ariane<span>-taxis.com</span></h2>
    <div class="cartes row">
      <div class="content row justify-content-evenly">
        <div class="col-8 col-md-3 cart">
          <img
            src="@/assets/pictures/aeroport.webp"
            alt="taxi-park-street"
            loading="lazy"
          />
          <h3 class="mb-3">Navette<br />Aéroport</h3>
          <p>
            <strong
              >Navette vers tous les aéroports de la région parisienne</strong
            >, y compris
            <strong>Roissy-Charles de Gaulle, Orly et Beauvais</strong>.
          </p>
        </div>
        <div class="col-8 col-md-3 cart">
          <img
            src="@/assets/pictures/gare.webp"
            alt="gare-montparnasse"
            loading="lazy"
          />
          <h3 class="mb-3 mx-auto">Navette<br />Gare Paris</h3>
          <p>
            Service pratique et fiable pour tous les voyageurs qui ont besoin
            d'un transport vers les <strong>gares</strong> dans
            <strong>les Yvelines et l'Île-de-France</strong>.
          </p>
        </div>
        <div class="col-8 col-md-3 cart">
          <img
            src="@/assets/pictures/acceuilHopital.webp"
            alt="taxi-front-hopital"
            loading="lazy"
          />
          <h3 class="mb-3">Transport<br />Médical</h3>
          <p>
            Spécialisé dans les déplacements médicaux en
            <strong>taxi conventionné</strong> pour les patients nécessitant un
            <strong>transport vers les hôpitaux et les cliniques</strong> de la
            région.
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  background-color: #101010;
  padding-bottom: 20px;
}

.text {
  position: absolute;
  top: 100px;
  left: auto;
  @media screen and (min-width: 992px) {
    position: absolute;
    top: 120px;
    left: auto;
  }
  h1 {
    display: block;
    font-family: sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-weight: bold;
    margin: 0px 10px;
    font-size: 1.6em;
    strong {
      color: $color-primary;
    }
  }
  @media screen and (max-width: 850px) {
    h1 {
      font-size: 1.2em;
      padding: 10px 10px;
    }
  }
}
h2 {
  text-align: end;
  position: relative;
  color: #ebd409;
  bottom: 60px;
  margin-right: 20px;
  @media screen and (min-width: 992px) {
    margin-right: 50px;
    bottom: 90px;
  }
  span {
    color: white;
  }
}

.header {
  background-image: linear-gradient(180deg, #00000073 50%, #10101061 100%),
    url("@/assets/pictures/taxis-spacex.webp");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
  box-shadow: inset 0 -50px 50px 5px #101010;
}

// Cart
.cartes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  margin-top: 30px;
}
.cart {
  width: 18rem;
  height: 22rem;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  color: $color-primary;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  margin: 20px 10px;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.2s ease-out;
  }
  h3 {
    position: absolute;
    inset: 250px auto 0px 10px;
    margin: 0;
    color: #ebd409;
    transition: inset 0.3s 0.3s ease-out;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px #000000aa;
  }

  p {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity 0.3s ease-out;
    inset: auto auto 80px 30px;
    color: white;
    text-align: start;
    @media screen and (min-width: 992px) {
      text-align: start;
    }
  }

  &:hover h3 {
    inset: auto auto 220px 30px;
    transition: inset 0.3s ease-out;
  }

  &:hover p {
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in;
  }

  &:hover img {
    transition: all 0.3s ease-in;
    opacity: 0.4;
    filter: blur(4px);
  }
}

// A partirdes tablette
@media screen and (max-width: 820px) {
  .cart {
    img {
      filter: brightness(60%);
    }
    h3 {
      position: absolute;
      inset: auto auto 220px 30px;
    }

    p {
      opacity: 1;
    }
  }
}
</style>
