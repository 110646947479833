import { createApp } from "vue";
import { createMetaManager } from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

axios.defaults.withCredentials = false;

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 404 && !originalRequest._retry) {
      originalRequest._retry = true;
      // store.dispatch("LogOut");
      return router.push("/404");
    }
  }
});

// create a global method for formatting the date string
const formatDate = (dateString) => {
  if (!dateString) {
    return "";
  }
  const options = { day: "numeric", month: "numeric", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", options);
};

// make the formatDate method available to all components
createApp(App)
  .use(router)
  .use(store)
  .use(createMetaManager())
  .mixin({
    methods: {
      formatDate: formatDate,
    },
  })
  .mount("#app");
