<template>
  <div class="speech col-6">
    <p class="title-header">Réservez votre taxi</p>
    <div class="hr"></div>
    <p>
      <strong>Réserver un taxi</strong> ou obtenir une
      <strong>estimation pour un trajet en taxi</strong>, rien de plus facile.
      Choisissez le lieu de départ et le point d'arrivée, on s'occupe du reste !
    </p>
  </div>
</template>

<script>
export default {
  name: "SpeechHeader",
};
</script>

<style lang="scss" scoped>
.speech {
  background-color: #0808087c;
  color: $color-secondary;
  border-radius: 30px;
  padding: 10px 20px;
  @media screen and (max-width: 992px) {
    display: none;
  }
  & .title-header {
    color: $color-secondary;
    font-size: 1.4em;
    font-weight: 600;
  }
  & .hr {
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: #ebd409;
  }
  & p {
    margin: 10px 5px;
    color: $color-secondary;
    font-size: 1em;
    @media only screen and (max-width: 1350px) and (min-width: 992px) {
      font-size: 0.9em;
    }
  }
}
</style>
