<template>
  <div>
    <HeaderContact />
  </div>
  <div>
    <MessageContact />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import HeaderContact from "@/components/contact/HeaderContact.vue";
import MessageContact from "@/components/contact/MessageContact.vue";

export default {
  components: { HeaderContact, MessageContact },
  setup() {
    useMeta({
      title: "Taxi Les Mureaux",
      description: [
        {
          content:
            "Vous cherchez un taxi à Les Mureaux ou dans les Yvelines ? Contactez Ariane-Taxis dès maintenant pour réserver votre taxi dès à présent. Notre équipe professionnelle et amicale est disponible 24 heures sur 24, 7 jours sur 7 pour répondre à vos besoins de transport. Que vous ayez besoin d'un taxi pour vos rendez-vous médicaux, vos déplacements professionnels ou personnels, ou pour vos transferts vers les aéroports de Paris et d'Ile-de-France, nous sommes là pour vous aider. Réservez dès maintenant en ligne ou par téléphone.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.ariane-taxis.com/taxi-les-mureaux-contact",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
