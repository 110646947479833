<template>
  <div class="container">
    <div class="login-box">
      <span class="red-cross" @click="closeOrder">X</span>
      <div class="user-box">
        <h5 class="order-info">
          Le montant de votre trajet est estimé à
          <span class="green">{{ price }}</span> €
        </h5>
        <h6 class="order-info pb-5">
          pour une durée de <span class="green">{{ duration }}</span
          >min et une distance d'environs
          <span class="green">{{ distance }}</span> km.
        </h6>
      </div>
      <form ref="formData" @submit.prevent="sendEmail">
        <div class="user-box">
          <input
            type="text"
            id="start"
            name="depart"
            :value="depart"
            required
            maxlength="70"
          />
          <label>Départ</label>
        </div>
        <div class="user-box">
          <input
            type="text"
            id="end"
            name="destination"
            :value="destination"
            required
            maxlength="70"
          />
          <label>Destination</label>
        </div>
        <div class="user-box">
          <input
            type="text"
            id="nom"
            name="lastname"
            v-model="nom"
            required
            maxlength="30"
          />
          <label>Nom</label>
        </div>
        <div class="user-box">
          <input
            type="text"
            id="prenom"
            name="firstname"
            v-model="prenom"
            required
            maxlength="30"
          />
          <label>Prenom</label>
        </div>
        <div class="user-box">
          <input
            type="tel"
            id="tel"
            name="phone"
            v-model="tel"
            required
            pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
          />
          <label>Téléphone</label>
        </div>
        <input type="hidden" name="duration" :value="duration" />
        <input type="hidden" name="price" :value="price" />
        <input type="hidden" name="distance" :value="distance" />
        <span class="mb-2" v-if="this.isCaptchaVisible">
          <vue-hcaptcha
            sitekey="9b384350-2c1b-44a6-a2b1-a5558d5b06bf"
            theme="dark"
            size="compact"
            @verify="onVerify"
            @error="onError"
          ></vue-hcaptcha>
        </span>
        <a href="#" @click.prevent="sendEmail">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Réservez
        </a>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
export default {
  name: "RideOrder",
  components: { VueHcaptcha },
  data() {
    return {
      nom: "",
      prenom: "",
      tel: "",
      isCaptchaVerified: false,
      isCaptchaVisible: false,
    };
  },
  props: {
    depart: String,
    destination: String,
    duration: String,
    distance: String,
    price: String,
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendBookingEmail"]),
    closeOrder() {
      this.$emit("closeOrder");
    },
    /*sendEmail(event) {
      event.preventDefault();
      this.SendBookingEmail(this.$refs.formData);
      this.$emit("openModalConfirm");
      this.$emit("closeOrder");
    },*/
    sendEmail(event) {
      if (this.isCaptchaVerified) {
        event.preventDefault();
        this.SendBookingEmail(this.$refs.formData);
        this.$emit("openModalConfirm");
        this.$emit("closeOrder");
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: #10eb09;
}
.red-cross {
  position: relative;
  top: -16px;
  left: -18px;
  display: inline;
  color: #fff;
  background: $color-primary;
  padding: 4px;
  border-radius: 50%;
  font-size: 1em;
  cursor: pointer;
}
.login-box {
  z-index: 9000;
  position: absolute;
  top: 0.6vh;
  left: 4vw;
  width: 90vw;
  padding: 20px;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 1);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 1);
  border-radius: 10px;
}

.order-info {
  margin: 0 0 20px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.user-box input:focus ~ label,
.user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #ebd409;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: $color-lighter;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 20px;
  letter-spacing: 4px;
  @media screen and (max-height: 725px) {
    margin-top: 10px;
  }
}

.login-box a:hover {
  background: #ebd409;
  color: $color-primary;
  border-radius: 5px;
  box-shadow: 0 0 5px $color-primary, 0 0 25px $color-primary,
    0 0 50px $color-primary, 0 0 100px $color-primary;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ebd409);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #ebd409);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #ebd409);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #ebd409);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
</style>
