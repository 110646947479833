<template>
  <div class="content slide-top">
    <div>
      <h2>Un taxi pour toutes vos envies<span>!</span></h2>
    </div>
    <section class="service">
      <div class="row justify-content-around">
        <div class="col-10 col-lg-3 services">
          <div class="hr"></div>
          <div class="title">
            <h3>Chauffeur privatisé</h3>
          </div>
          <div class="text">
            <p>
              Votre <strong>chauffeur privé</strong> pour des
              <strong>trajets en taxi quotidiens</strong> en fonction de vos
              besoins.
            </p>
          </div>
        </div>
        <div class="col-10 col-lg-3 services">
          <div class="hr"></div>
          <div class="title">
            <h3>Evénement</h3>
          </div>
          <div class="text">
            <p>
              Besoin d’un <strong>taxi</strong> pour un événement, mariage,
              anniversaire, séminaire, réception…
            </p>
          </div>
        </div>
        <div class="col-10 col-lg-3 services">
          <div class="hr"></div>
          <div class="title">
            <h3>Transfert Aéroport</h3>
          </div>
          <div class="text">
            <p>
              Votre <strong>taxi</strong> vous attend. N’hésitez pas pour un
              <strong>service de transfert en taxi vers l’aéroport</strong> de
              votre choix.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-around">
        <div class="col-10 col-lg-3 services">
          <div class="hr"></div>
          <div class="title">
            <h3>Transfert Gare</h3>
          </div>
          <div class="text">
            <p>
              L’assurance de na pas rater son train, un
              <strong>transfert en taxi vers la gare</strong>
              de votre choix.
            </p>
          </div>
        </div>
        <div class="col-10 col-lg-3 services">
          <div class="hr"></div>
          <div class="title">
            <h3>Circuit Touristique</h3>
          </div>
          <div class="text">
            <p>
              Envie de découvrir les plus beaux endroit de
              <strong>Paris</strong> ? Ariane-<strong>taxis</strong> vous
              accompagne dans une visite des lieux cultes de
              <strong>Paris</strong>.
            </p>
          </div>
        </div>
        <div class="col-10 col-lg-3 services">
          <div class="hr"></div>
          <div class="title">
            <h3>Transfert Médicaux</h3>
          </div>
          <div class="text">
            <p>
              Chez Ariane-taxis, nous sommes fiers d'offrir un service de
              transfert en <strong>taxi conventionné</strong> par la
              <strong>Caisse Primaire d’Assurance Maladie (CPAM)</strong>.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    // Effet d'apparition
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
        }
      });
    });

    const elementsTop = document.querySelectorAll(".slide-top");
    elementsTop.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 100px;
}
h2 {
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #000000aa;
  text-align: center;
  & span {
    margin-left: 8px;
  }
}

.service {
  margin-top: 70px;
  .services {
    margin-bottom: 70px;
  }
  .hr {
    margin-bottom: 10px;
    height: 1px;
    background-color: $color-primary;
    width: 90%;
  }
  h3 {
    margin-bottom: 20px;
    color: black;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1.7em;
  }
  p {
    color: rgb(92, 92, 92);
  }
}

.slide-top {
  transform: translateY(30%);
  opacity: 0;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.slide-top.active {
  transform: translateY(0%);
  opacity: 1;
}
</style>
