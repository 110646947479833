import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ServiceView from "../views/ServiceView.vue";
import ContactView from "../views/ContactView.vue";
import MentionLegal from "@/views/MentionLegal.vue";
import MureauxView from "@/views/landingpages/MureauxView.vue";
import BazemontView from "@/views/landingpages/BazemontView.vue";
import BouafleView from "@/views/landingpages/BouafleView.vue";
import ChapetView from "@/views/landingpages/ChapetView.vue";
import EcquevillyView from "@/views/landingpages/EcquevillyView.vue";
import JuziersView from "@/views/landingpages/JuziersView.vue";
import MorainvilliersView from "@/views/landingpages/MorainvilliersView.vue";
import NezelView from "@/views/landingpages/NezelView.vue";
import VerneuilView from "@/views/landingpages/VerneuilView.vue";
import VernouilletView from "@/views/landingpages/VernouilletView.vue";
import MeulanView from "@/views/landingpages/MeulanView.vue";
import FlinsView from "@/views/landingpages/FlinsView.vue";
import AubergenvilleView from "@/views/landingpages/AubergenvilleView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  /*{
    path: "/taxi-les-mureaux-yvelines",
    name: "home",
    component: HomeView,
  },*/
  {
    path: "/taxi-les-mureaux-service",
    name: "service",
    component: ServiceView,
  },
  {
    path: "/taxi-les-mureaux-contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/mentions",
    name: "mentions",
    component: MentionLegal,
  },
  {
    path: "/les-mureaux-taxi",
    name: "taxi-mureaux",
    component: MureauxView,
  },
  {
    path: "/bazemont-taxi",
    name: "taxi-bazemont",
    component: BazemontView,
  },
  {
    path: "/bouafle-taxi",
    name: "taxi-bouafle",
    component: BouafleView,
  },
  {
    path: "/chapet-taxi",
    name: "taxi-chapet",
    component: ChapetView,
  },
  {
    path: "/ecquevilly-taxi",
    name: "taxi-ecquevilly",
    component: EcquevillyView,
  },
  {
    path: "/juziers-taxi",
    name: "taxi-juziers",
    component: JuziersView,
  },
  {
    path: "/morainvilliers-taxi",
    name: "taxi-morainvilliers",
    component: MorainvilliersView,
  },
  {
    path: "/nezel-taxi",
    name: "taxi-nezel",
    component: NezelView,
  },
  {
    path: "/verneuil-taxi",
    name: "taxi-verneuil",
    component: VerneuilView,
  },
  {
    path: "/vernouillet-taxi",
    name: "taxi-vernouillet",
    component: VernouilletView,
  },
  {
    path: "/meulan-taxi",
    name: "taxi-meulan",
    component: MeulanView,
  },
  {
    path: "/flins-sur-seine-taxi",
    name: "taxi-flins",
    component: FlinsView,
  },
  {
    path: "/aubergenville-taxi",
    name: "taxi-aubergenville",
    component: AubergenvilleView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
