<template>
  <div>
    <HeaderService />
  </div>
  <OrderTaxi />
  <div>
    <ServiceTaxi />
  </div>
  <div>
    <FAQ />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import OrderTaxi from "@/components/service/OrderTaxi.vue";
import HeaderService from "@/components/service/HeaderService.vue";
import ServiceTaxi from "@/components/service/ServiceTaxi.vue";
import FAQ from "@/components/service/FAQ.vue";

export default {
  components: { HeaderService, OrderTaxi, ServiceTaxi, FAQ },
  setup() {
    useMeta({
      title: "Votre taxi aux Mureaux",
      description: [
        {
          content:
            "Nos services de taxi sur Les Mureaux et dans tout le département des yvelines vous offrent des options de transport flexibles et abordables. Nous sommes disponibles 24 heures sur 24, 7 jours sur 7 pour répondre à vos besoins de transport locaux et longue distance. Nous proposons également des transferts vers les aéroports de Paris et d'Ile-de-France. Réservez dès maintenant en ligne ou par téléphone pour bénéficier de notre service fiable et professionnel.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.ariane-taxis.com/taxi-les-mureaux-service",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
