<template>
  <div>
    <HeaderHome />
    <div>
      <ServicesHome />
    </div>
    <div>
      <CoordonneHome />
    </div>
  </div>
  <div>
    <PresentationHome />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import HeaderHome from "@/components/home/HeaderHome.vue";
import PresentationHome from "@/components/home/PresentationHome.vue";
import CoordonneHome from "@/components/home/CoordonneHome.vue";
import ServicesHome from "@/components/home/ServicesHome.vue";

export default {
  components: {
    HeaderHome,
    PresentationHome,
    CoordonneHome,
    ServicesHome,
  },
  setup() {
    useMeta({
      title: "Taxi Les Mureaux & Yvelines",
      description: [
        {
          content:
            "Réservez un taxi à Les Mureaux ou dans les Yvelines avec Ariane-taxis une entreprise de taxi professionnelle et fiable qui propose des services de transport locaux et longue distance. Nos chauffeurs expérimentés vous conduiront en toute sécurité et à temps à votre destination, que vous ayez besoin d'un taxi pour vos trajets quotidiens ou pour des transferts vers les aéroports de Paris et d'Ile-de-France. Contactez-nous dès maintenant pour réserver votre taxi.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.ariane-taxis.com",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
