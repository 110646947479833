<template>
  <div class="content">
    <!-- Section 1 -->
    <h2 class="text-center slide-left">
      {{ town }}:<br />Trouvez un taxi rapidement
    </h2>
    <section class="text_left gare">
      <div class="row justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-left">
          <div class="title">
            <h2>{{ town }}: Réservez un Taxi pour Gares & Aéroports</h2>
          </div>
          <div class="paragraphe">
            <p>
              Besoin d'un <strong>taxi à {{ town }}</strong> pour rejoindre une
              gare ou un aéroport ? Réservez avec Ariane <strong>Taxis</strong>,
              votre partenaire de confiance. Nos chauffeurs vous assurent un
              trajet confortable et ponctuel en
              <strong>taxi à {{ town }}</strong> vers toutes les destinations
              importantes.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-right">
          <div class="image">
            <img
              src="@/assets/pictures/terminal-retro.webp"
              alt="photo-aeroport"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Section 2 -->
    <section class="text_right">
      <div class="row spacing flex-lg-row-reverse justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-right">
          <div class="title">
            <h2 class="exeption-r">
              {{ town }}: Commandez un taxi pour vos Trajets Quotidiens
            </h2>
          </div>
          <div class="paragraphe">
            <p class="exeption-r">
              Pour vos trajets quotidiens, faites confiance à Ariane
              <strong>Taxis</strong>. Que ce soit pour aller au travail, à
              l'école ou pour toute autre activité régulière, nous vous offrons
              un service de <strong>taxi à {{ town }}</strong> sûr et
              confortable. Nos tarifs pour les
              <strong>taxis à {{ town }}</strong> sont compétitifs et
              transparents. Profitez de la tranquillité d'esprit avec notre
              service de <strong>taxi à {{ town }}</strong> pour tous vos
              besoins de déplacement quotidien.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-left">
          <div class="image">
            <img
              src="@/assets/pictures/taxi-daily.webp"
              alt="taxi-in-paris"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Section 3 -->
    <section class="text_left touristique">
      <div class="row justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-left">
          <div class="title">
            <h2>{{ town }}: Professionnalisme des Taxis vs VTC</h2>
          </div>
          <div class="paragraphe">
            <p>
              Lorsque vous choisissez un <strong>taxi à {{ town }}</strong
              >, vous optez pour un service de transport professionnel et
              sécurisé. Nos chauffeurs de <strong>taxis</strong> sont des
              professionnels expérimentés, formés pour respecter strictement les
              règles de conduite et assurer votre sécurité à tout moment.
              Contrairement aux <strong>VTC</strong>, souvent gérés par des
              chauffeurs cumulant plusieurs emplois et effectuant de longues
              heures de route, nos chauffeurs de <strong>taxis</strong> se
              concentrent exclusivement sur votre sécurité et votre confort.
              Choisir un <strong>taxi à {{ town }}</strong> signifie bénéficier
              d'un service personnalisé, d'une tarification réglementée et d'une
              tranquillité d'esprit totale pour tous vos trajets, que ce soit
              pour les affaires, les loisirs ou les trajets quotidiens.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-right">
          <div class="image">
            <img
              src="@/assets/pictures/taxi-vtc.webp"
              alt="professional-taxi"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Section 4 -->
    <section class="text_right">
      <div class="row spacing flex-lg-row-reverse justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-right">
          <div class="title">
            <h2 class="exeption-r">Trouvez Rapidement un Taxi à {{ town }}</h2>
          </div>
          <div class="paragraphe">
            <p class="exeption-r">
              Besoin de
              <strong>réserver un taxi à {{ town }}</strong> rapidement ? Ariane
              <strong>Taxis</strong> offre un service de réservation simple et
              efficace pour tous vos déplacements. Que ce soit pour un trajet
              vers une gare, un aéroport ou pour vos trajets quotidiens, nous
              sommes là pour vous fournir un
              <strong>taxi à {{ town }}</strong> sûr et confortable. Réservez
              dès maintenant et profitez de notre fiabilité à toute épreuve.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-left">
          <div class="image">
            <img
              src="@/assets/pictures/call-finger.webp"
              alt="taxi-station"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Section 5 -->
    <section class="text_left gare">
      <div class="row justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-left">
          <div class="title">
            <h2>{{ town }}: Service de Taxi de Nuit à {{ town }}</h2>
          </div>
          <div class="paragraphe">
            <p>
              Besoin d'un <strong>taxi à {{ town }}</strong> tard dans la nuit ?
              Ariane <strong>Taxis</strong> propose un service de
              <strong>taxi 24/7 à {{ town }}</strong
              >. Nos chauffeurs sont disponibles pour répondre à vos besoins de
              déplacement à toute heure. Profitez de notre service fiable de
              <strong>taxi à {{ town }}</strong> pour rentrer chez vous en toute
              sécurité après une soirée bien remplie.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-right">
          <div class="image">
            <img
              src="@/assets/pictures/taxi-night.webp"
              alt="taxi-night"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Section 6 -->
    <section class="text_right touristique">
      <div class="row spacing flex-lg-row-reverse justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-right">
          <div class="title">
            <h2 class="exeption-r">
              Réservez un Taxi à {{ town }} pour vos Visites Touristiques
            </h2>
          </div>
          <div class="paragraphe">
            <p class="exeption-r">
              Planifiez votre visite touristique à
              <strong>{{ town }} avec Ariane Taxis</strong>. Nous proposons des
              circuits personnalisés en <strong>taxi à {{ town }}</strong> pour
              découvrir les attractions locales et les lieux emblématiques. Nos
              chauffeurs expérimentés vous garantissent une expérience agréable
              informative en <strong>taxi à {{ town }}</strong
              >. Réservez votre tour dès aujourd'hui et laissez-nous vous
              conduire vers des moments mémorables.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-left">
          <div class="image">
            <img
              src="@/assets/pictures/tourist-taxi.webp"
              alt="tourist-taxi"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServiceTaxiBis",
  props: {
    town: String,
  },
  mounted() {
    // Effet d'apparition
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
        }
      });
    });

    const elementsRight = document.querySelectorAll(".slide-right");
    elementsRight.forEach((element) => {
      observer.observe(element);
    });

    const elementsLeft = document.querySelectorAll(".slide-left");
    elementsLeft.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style lang="scss" scoped>
//
.spacing {
  @media screen and (min-width: 992px) {
    margin: 120px 0px;
  }
}
.exeption-r {
  @media screen and (min-width: 992px) {
    text-align: left !important;
  }
}
.content {
  max-width: 1400px;
  overflow: hidden;
  margin: auto;
  padding: 30px 0px;
  @media screen and (max-height: 500px) {
    padding: 100px 0px;
    margin-top: 150px;
  }
  @media screen and (min-width: 576px) {
    width: 100%;
  }
}
section {
  margin-top: 70px;
}
h1 {
  color: $color-primary;
  margin-top: 100px;
  margin-bottom: 180px;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 2.2em;
  @media screen and (max-width: 992px) {
    font-size: 1.8em;
    margin-top: 70px;
    margin-bottom: 90px;
    padding: 0px 15px;
  }
}
h2 {
  margin-bottom: 30px;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #000000aa;
  text-align: center;
  @media screen and (min-width: 992px) {
    text-align: right;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 15px;
  }
}
p {
  text-align: start;
  padding: 0px 0px;
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    text-align: end;
    padding: 0px 10px;
  }
}
.image {
  max-width: 100%;
  padding: 0px 3px 3px 0px;
  img {
    width: 100%;
    height: 290px;
  }
  @media screen and (max-width: 992px) {
    margin-top: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

// Gare
.text_left {
  .img_content {
    padding: 20px;
    .image {
      background-color: $color-primary;
      padding: 0px 0px 3px 3px;
      img {
        position: relative;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}
.text_right {
  .img_content {
    padding: 20px;
    .image {
      background-color: #ebd409;
      img {
        position: relative;
        bottom: 30px;
        left: 30px;
      }
    }
  }
}

// Small ecran
@media screen and (max-width: 576px) {
  .text_left,
  .text_right {
    .img_content {
      padding: 0;
      .image {
        width: 100%;
        border: none;
        img {
          width: 100%;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}

// Animation Apparation gauche
.slide-left {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-left.active {
  transform: translateX(0%);
  opacity: 1;
}

// Animation Apparation droite
.slide-right {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-right.active {
  transform: translateX(0%);
  opacity: 1;
}
</style>
