<template>
  <div>
    <HeaderTaxi town="Morainvilliers" />
    <BtnCall />
    <div>
      <CardTaxi town="Morainvilliers" />
    </div>
    <div>
      <ArianeTaxi town="Morainvilliers" />
    </div>
    <div>
      <CallTaxi town="Morainvilliers" />
    </div>
    <div>
      <ServiceTaxi town="Morainvilliers" />
    </div>
    <div>
      <OrderTaxi welcome="Morainvilliers" town="Morainvilliers" />
    </div>
    <div>
      <ServiceTaxiBis town="Morainvilliers" />
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import HeaderTaxi from "@/components/landingpage/HeaderTaxi.vue";
import ArianeTaxi from "@/components/landingpage/ArianeTaxi.vue";
import CardTaxi from "@/components/landingpage/CardTaxi.vue";
import ServiceTaxi from "@/components/landingpage/ServiceTaxi.vue";
import CallTaxi from "@/components/landingpage/CallTaxi.vue";
import OrderTaxi from "@/components/landingpage/OrderTaxi.vue";
import ServiceTaxiBis from "@/components/landingpage/ServiceTaxiBis.vue";
import BtnCall from "@/components/partial/BtnCall.vue";

export default {
  components: {
    HeaderTaxi,
    ArianeTaxi,
    CardTaxi,
    ServiceTaxi,
    CallTaxi,
    OrderTaxi,
    ServiceTaxiBis,
    BtnCall,
  },
  setup() {
    useMeta({
      title: "Taxi Morainvilliers",
      description: [
        {
          content:
            "Réservez un taxi à Morainvilliers avec Ariane-taxis une entreprise de taxi professionnelle et fiable qui propose des services de transport locaux et longue distance. Nos chauffeurs expérimentés vous conduiront en toute sécurité et à temps à votre destination, que vous ayez besoin d'un taxi pour vos trajets quotidiens ou pour des transferts vers les aéroports de Paris et d'Ile-de-France. Contactez-nous dès maintenant pour réserver votre taxi.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.ariane-taxis.com/morainvilliers-taxi",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
