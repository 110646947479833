<template>
  <section class="container-fluid">
    <div class="titleForm">
      <h2>
        Prix Taxi {{ town }}<br />
        <span>estimation</span>
      </h2>
    </div>
    <div class="form">
      <div class="col-12 col-lg-3">
        <p class="right-align">
          Vous cherchez un service de <strong>taxi à {{ town }}</strong> fiable
          et abordable ? Ariane <strong>Taxis</strong> est là pour vous offrir
          une expérience de transport confortable et sans stress. Contrairement
          à ce que l'on pourrait penser, les <strong>VTC</strong> ne sont pas
          toujours moins chers que les <strong>taxis à {{ town }}</strong
          >. Avec notre simulateur de prix, vous pouvez obtenir une estimation
          précise du coût de votre trajet en
          <strong>taxi à {{ town }}</strong> en quelques clics. Nos tarifs sont
          transparents et compétitifs, vous évitant ainsi les mauvaises
          surprises.
        </p>
      </div>
      <div class="wrapper">
        <FormRide welcome="Simulation" />
      </div>
      <div class="col-12 col-lg-3">
        <p>
          N'hésitez pas à utiliser notre simulateur pour évaluer le coût de
          votre prochaine course en <strong>taxi à {{ town }}</strong
          >. Si vous avez des questions ou des besoins spécifiques, notre équipe
          est à votre disposition pour vous fournir toutes les informations
          nécessaires. Chez Ariane <strong>Taxis</strong>, votre satisfaction
          est notre priorité, et nous nous efforçons de vous offrir le meilleur
          service de <strong>taxi à {{ town }}</strong> à chaque trajet.
          Réservez dès maintenant et découvrez la différence avec Ariane
          <strong>Taxis</strong>.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import FormRide from "@/components/partial/FormRide.vue";
export default {
  components: { FormRide },
  props: {
    town: String,
  },
};
</script>

<style lang="scss" scoped>
.titleForm {
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    color: $color-primary;
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 0 15px;
    text-transform: uppercase;
    font-size: 2.2em;
    text-align: center;
    line-height: -80px;
    & span {
      font-size: 0.5em;
      color: black;
    }
    @media screen and (min-width: 992px) {
      font-size: 1.8em;
      margin-top: 70px;
      margin-bottom: 50px;
      padding: 0px 15px;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 100px;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
  & .right-align {
    text-align: center;
    @media screen and (min-width: 992px) {
      text-align: right;
    }
  }
  & p {
    background-color: $color-primary;
    padding: 10px 20px;
    border: 1px outset #ebd409;
    border-radius: 10px;
    color: white;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
  @media screen and (min-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0px 0px 30px;
  }
}
</style>
