<template>
  <div class="content slide-top">
    <div class="image text-center">
      <img
        src="@/assets/undraw/ask.webp"
        alt="three-monster-wondering"
        loading="lazy"
      />
    </div>
    <section class="body">
      <div class="container">
        <h2>Les questions fréquentes</h2>
        <div class="accordion">
          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title"
                >Quels services propose <strong>Ariane-taxis</strong> ?</span
              >
              <span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                <strong>Ariane-taxis</strong> propose des services de transport
                en <strong>taxi à Les Mureaux</strong> et sur tout le secteur
                limitrophe. Nous assurons des trajets locaux, longue distance,
                vers les aéroports et gares, ainsi que des services de transport
                pour les événements spéciaux.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-2" aria-expanded="false">
              <span class="accordion-title"
                >Comment faire une <strong>réservation de taxi</strong> chez
                <strong>Ariane-taxis</strong> ?</span
              >
              <span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Pour réserver un <strong>taxi</strong> chez
                <strong>Ariane-taxis</strong>, vous pouvez nous contacter par
                téléphone ou via notre site web et son module de réservation.
                Indiquez votre lieu de prise en charge sur le secteur de
                <strong>Les Mureaux</strong>, votre destination et obtenez une
                estimation du prix de votre course.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-3" aria-expanded="false">
              <span class="accordion-title"
                >Quelles sont les disponibilitées de
                <strong>réservation taxi</strong> chez
                <strong>Ariane-taxis</strong> ?</span
              >
              <span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Oui, chez <strong>Ariane-taxis</strong>, nous proposons un
                <strong>service de taxi</strong> disponible 24 heures sur 24, 7
                jours sur 7 sur réservation préalable. Que vous ayez besoin d'un
                <strong>taxi</strong> tôt le matin, tard dans la nuit ou à tout
                autre moment de la journée, nous sommes là pour répondre à vos
                besoins de transport.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-4" aria-expanded="false">
              <span class="accordion-title"
                >Quels sont les
                <strong>tarifs et modes de paiement</strong> acceptés chez
                <strong>Ariane-taxis</strong> ?</span
              >
              <span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Les tarifs chez <strong>Ariane-taxis</strong> sont basés sur la
                distance parcourue et le type de service requis. Nous acceptons
                les paiements en espèces et par carte bancaire pour offrir la
                commodité à nos clients.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-5" aria-expanded="false">
              <span class="accordion-title"
                >Pouvons-nous <strong>modifier ou faire annuler</strong> une
                réservation d'un <strong>taxi</strong> ?</span
              >
              <span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Oui, chez <strong>Ariane-taxis</strong>, vous pouvez modifier ou
                annuler votre <strong>réservation de taxi</strong>. Nous vous
                prions cependant de nous contacter dès que possible pour toute
                modification ou annulation afin de faciliter la gestion de notre
                planning.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded");

      for (let i = 0; i < items.length; i++) {
        items[i].setAttribute("aria-expanded", "false");
      }

      if (itemToggle == "false") {
        this.setAttribute("aria-expanded", "true");
      }
    }

    items.forEach((item) => item.addEventListener("click", toggleAccordion));

    // Effet d'apparition
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
        }
      });
    });

    const elementsTop = document.querySelectorAll(".slide-top");
    elementsTop.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 100px;
}
.body {
  margin: 0;
  padding: 0;
  font-family: "Hind", sans-serif;
  display: flex;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  padding: 2rem;
  width: 58rem;
}
h2 {
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 1.8em;
  text-shadow: 1px 1px 2px #000000aa;
}
.image {
  width: 100%;
  img {
    width: 40%;
    margin-bottom: 50px;
    margin-top: -40px;
    @media screen and (max-width: 992px) {
      width: 70%;
    }
  }
}

.accordion {
  .accordion-item {
    padding: 0 10px;
    background-color: $color-secondary;
    border-bottom: 1px solid $color-primary-light;
    button[aria-expanded="true"] {
      border-bottom: 1px solid $color-primary;
    }
  }
  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: $color-primary-dark;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    &:hover,
    &:focus {
      cursor: pointer;
      color: $color-primary;
      &::after {
        cursor: pointer;
        color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
    .accordion-title {
      padding: 1em 1.5em 1em 0;
      margin-right: 2px;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 15px;
      right: -5px;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: "";
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: "";
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  button[aria-expanded="true"] {
    color: $color-primary;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: 85em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
  .accordion-content {
    opacity: 0;
    max-height: 0;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    p {
      font-size: 1rem;
      font-weight: 300;
      margin: 2em 0;
    }
    li {
      list-style-position: inside;
    }
  }
}

.slide-top {
  transform: translateY(30%);
  opacity: 0;
  transition: transform 0.7s ease-in-out, opacity 1s ease-in-out;
}
.slide-top.active {
  transform: translateY(0%);
  opacity: 1;
}
</style>
