import { createStore } from "vuex";
import mailjs from "./modules/mailjs";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    mailjs,
  },
});
