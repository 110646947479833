<template>
  <section class="container-fluid">
    <div class="container speech-contact">
      <h1>Réservez un taxi à {{ town }} dès maintenant !</h1>
      <div class="hr"></div>
      <p>Contactez-nous.</p>
      <p>24/7</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeaderTaxi",
  props: {
    town: String,
  },
};
</script>

<style lang="scss" scoped>
section {
  background-image: url("@/assets/pictures/taxi.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.speech-contact {
  padding: 70px 10px;
}
h1 {
  color: $color-secondary;
  position: relative;
  font-weight: 400;
  text-transform: uppercase;
}
p {
  color: $color-lighter;
  position: relative;
  font-size: 1.1em;
}
.hr {
  margin-top: 8px;
  width: 100%;
  height: 1px;
  background-color: #ebd409;
}
</style>
