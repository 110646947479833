<template>
  <div class="position">
    <span v-if="showButton">
      <div class="container-fluid">
        <div class="d-flex justify-content-end">
          <div class="call-nav">
            <button
              class="shadow__btn"
              :class="{ 'animate-hover': animateHover }"
            >
              <a href="tel:+33180883015"> &#128222; Appelez-nous</a>
            </button>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: "BtnCall",
  data() {
    return {
      showButton: false,
      animateHover: false,
      scrollHeight: 100, // Hauteur de défilement pour afficher le bouton
      scrollBottomHeight: 100, // Hauteur de défilement à partir du bas pour masquer le bouton
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
      this.startAnimation();
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollBottom = documentHeight - (scrollTop + windowHeight);

      if (
        scrollTop > this.scrollHeight &&
        scrollBottom > this.scrollBottomHeight
      ) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
    startAnimation() {
      setInterval(() => {
        this.animateHover = true;
        setTimeout(() => {
          this.animateHover = false;
        }, 2000); // Animation dure 2 secondes
      }, 5000); // Répéter toutes les 5 secondes
    },
  },
};
</script>

<style lang="scss" scoped>
.position {
  position: fixed;
  top: 10px;
  left: 30px;
  z-index: 1000;
  @media screen and (min-width: 992px) {
    right: 50px;
  }
}
.shadow__btn {
  padding: 10px 20px;
  border: none;
  font-size: 10px;
  color: #fff;
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  & a {
    color: $color-darker !important;
  }
}

.number {
  @media screen and (min-width: 992px) {
    margin-left: 80px;
  }
}

.shadow__btn {
  background: #e9e90a;
  box-shadow: 0 0 25px $color-primary;
  margin-top: 20px;
  @media screen and (min-width: 992px) {
    margin-left: 80px;
  }
}

.shadow__btn:hover {
  box-shadow: 0 0 5px #e9e90a, 0 0 25px #e9e90a, 0 0 50px #e9e90a,
    0 0 100px #e9e90a;
  & a:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 768px) {
  .animate-hover {
    box-shadow: 0 0 5px #e9e90a, 0 0 25px #e9e90a, 0 0 50px #e9e90a,
      0 0 100px #e9e90a;
  }
}

.number {
  color: black;
  font-size: 0.8em;
}
</style>
