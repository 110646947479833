<template>
  <div id="sticky-top" class="container-fluid sticky-top">
    <div class="row menu">
      <nav class="col navbar navbar-expand-lg navbar-dark menu__nav">
        <a href="/" class="navbar-brand brand-name d-flex">
          <img
            src="@/assets/brand/logo-light.webp"
            alt="logo ariane taxi"
            width="120"
            class="mt-lg-2 pb-lg-2 mx-2"
          />
        </a>

        <button
          class="navbar-toggler mr-5"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          id="buttonTogling"
          title="Togling"
        >
          <!----<span class="navbar-toggler-icon"></span>-->
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div id="navbarContent" class="collapse navbar-collapse mx-lg-5">
          <ul class="navbar nav ms-auto ul mt-3 text-justify">
            <li class="nav-item">
              <a
                id="taxi-les-mureaux-yvelines-78"
                class="nav-link scroll-link"
                href="/"
                >Accueil</a
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item">
              <a
                id="service-taxi-les-mureaux-yvelines-78"
                class="nav-link scroll-link"
                href="/taxi-les-mureaux-service"
                >Services</a
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item">
              <a
                id="contact-taxi-les-mureaux-yvelines-78"
                class="nav-link scroll-link"
                href="/taxi-les-mureaux-contact"
                >Contact</a
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>
          </ul>
          <div class="d-flex justify-content-center">
            <div class="call-nav">
              <button class="shadow__btn">
                <a href="tel:+33180883015"> &#128222; Appelez-nous</a>
              </button>
              <p class="number">01.80.88.30.15</p>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <BtnCall />
  </div>
</template>

<script>
import BtnCall from "@/components/partial/BtnCall.vue";
export default {
  name: "NavBase",
  components: { BtnCall },
  methods: {
    setActiveLink() {
      const navLinks = document.querySelectorAll(".ul a");

      navLinks.forEach((link) => {
        link.classList.remove("link-active");
        if (link.getAttribute("id") === window.location.pathname.slice(1)) {
          link.classList.add("link-active");
        }
      });
    },
  },
  mounted() {
    this.setActiveLink(); // Appeler la méthode lors du chargement de la page
  },
};
</script>

<style lang="scss" scoped>
.menu {
  z-index: 800;
  padding: 10px;
  background: $color-primary;
  @media screen and (min-width: 992px) {
    padding: 0px;
  }
  & .brand-name {
    @media screen and (min-width: 992px) {
      margin: 0px 50px;
    }
  }
  & .brand-name:hover {
    & p {
      color: $color-text;
    }
    & span {
      color: $color-primary;
    }
  }
  &__span {
    color: $color-text;
  }
  & .nav-item {
    font-size: 1em;
    padding: 5px 0px;
    @media screen and (max-width: 768px) {
      margin: 2px;
    }
  }
  &__nav {
    margin-bottom: 0px;
    & a:not(.brand-name) {
      padding: 0px 5px;
      color: $color-lighter;
      text-decoration: none;
      &:hover {
        text-decoration: underline rgb(233, 233, 10);
        text-underline-offset: 4px;
      }
      &.link-active {
        color: rgb(233, 233, 10);
      }
    }
  }
}
#navbarContent {
  width: fit-content;
}
.navbar-toggler {
  color: $color-lighter;
  border: none;
}
.phone {
  border: 1px outset $color-primary;
  border-radius: 9%;
  font-size: 0.6em;
  margin-left: 20px;
  padding: 3px;
  & a {
    color: $color-primary;
    & a:hover {
      color: $color-text;
    }
  }
}

.ul {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: end;
  }
  & li {
    & a {
      font-size: 1em;
      @media screen and (min-width: 992px) {
        font-size: 1.1em;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .s-100 {
    height: 100vw;
  }
}
.active {
  position: relative;
  width: 30px;
  height: 20px;
  left: 16%;
  top: -58px;
  background-color: $color-primary;
  transform: rotate(45deg);
}
.navbar-dark .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.navbar-dark .navbar-toggler:hover span,
.navbar-dark .navbar-toggler:focus span {
  background: #e9e90a;
}

/* rotate first span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}

/* hide second span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

/* rotate third span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-dark .navbar-toggler[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}

.navbar-dark .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}

/* rotate first span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}

/* hide second span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

/* rotate third span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-dark .navbar-toggler[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}
.shadow__btn {
  padding: 10px 20px;
  border: none;
  font-size: 10px;
  color: #fff;
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  & a {
    color: $color-darker !important;
  }
}

.number {
  @media screen and (min-width: 992px) {
    margin-left: 80px;
  }
}

.shadow__btn {
  background: #e9e90a;
  box-shadow: 0 0 25px rgb(0, 140, 255);
  margin-top: 20px;
  @media screen and (min-width: 992px) {
    margin-left: 80px;
  }
}

.shadow__btn:hover {
  box-shadow: 0 0 5px #e9e90a, 0 0 25px #e9e90a, 0 0 50px #e9e90a,
    0 0 100px #e9e90a;
  & a:hover {
    text-decoration: none;
  }
}
.number {
  color: #e9e90a;
  font-size: 0.8em;
}
.call-nav {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}
</style>
