<template>
  <div class="content">
    <footer class="text-center">
      <div class="hr-div">
        <div class="hr"></div>
      </div>
      <div class="container-fluid d-lg-flex align-items-center">
        <div class="col-lg-4 footer-brand">
          <a class="text-white" href="/">
            <img
              src="@/assets/brand/logo-light.webp"
              alt="logo-taxi-seine"
              class="mb-4 mx-2"
              width="250"
            />
          </a>
        </div>
        <div class="col-lg-4 mx-4 mx-lg-2 footer-speech">
          <p>
            Ariane-<strong>taxis</strong> opère sur
            <strong>Les Mureaux</strong> et dans les <strong>Yvelines</strong>.
            Nos chauffeurs de <strong>taxi</strong> expérimentés sont à votre
            disposition pour répondre à tous vos besoins de transport.
            <strong>Réservez votre taxi</strong> dès maintenant et profitez d'un
            trajet agréable et sans stress.
          </p>
          <div
            class="coord d-flex flex-column flex-lg-row justify-content-between"
          >
            <p>Place de la libération,<br />78130 Les Mureaux</p>
            <p>Téléphone:<br />01.80.88.30.15</p>
          </div>
        </div>
        <div class="col-lg-3 footer-call">
          <a href="tel:+33180883015">
            <img
              src="@/assets/icons/icon-handphone.webp"
              alt="icon-phone"
              width="80"
              class="mb-4 mx-2"
            />
          </a>
        </div>
      </div>

      <!-- Copyright -->
      <div class="malaweb text-center text-white p-3">
        <a class="text-white" href="/mentions">©2024 </a>
        <a class="text-white" href="https://malaweb.fr/">Powered by Malaweb</a>
      </div>
      <!-- Copyright -->
    </footer>
  </div>
  <!-- End of .container -->
</template>

<style lang="scss" scoped>
footer {
  background-color: $color-darker;
  color: $color-secondary;
  & .hr-div {
    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: center;
    }
    .hr {
      width: 100%;
      height: 2px;
      background-color: #ebe707;
      margin-top: 40px;
      margin-bottom: 20px;
      @media screen and (min-width: 992px) {
        width: 80%;
      }
    }
  }
}
.malaweb {
  background-color: $color-primary;
  margin-top: 50px;
}
.footer-speech {
  text-align: center;
  & p {
    color: $color-lighter;
  }
  @media screen and (max-width: 992px) {
    margin-top: 0px;
    margin-bottom: 50px;
    text-align: center;
  }
}
.footer-call {
  img {
    //border: 0.5px outset $color-primary;
    border-radius: 50%;
    padding: 15px;
    background-color: $color-primary;
    transition: all 0.3s;
    @media screen and (max-width: 992px) {
      margin-bottom: 90px;
    }
    &:hover {
      border: 0.5px solid $color-primary;
      background: $color-primary;
      padding: 15px;
      transform: scale(0.96);
      transition: all 0.3s;
    }
  }
}
.coord {
  margin-top: 20px;
  padding: 10px 18px;
  border-radius: 10px;
  background: #ebe707;
  box-shadow: inset 22px 22px 44px #ebe707, inset -22px -22px 44px #bdba0d;
  p {
    color: $color-darker;
    font-weight: bold;
    font-size: 0.8em;
  }
}
</style>
