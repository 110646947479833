<template>
  <div class="content">
    <h1 class="text-center slide-left">Les Mureaux:<br />Réserver un taxi</h1>
    <section class="text_left gare">
      <div class="row justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-left">
          <div class="title">
            <h2>Transfert Gares & Aéroports</h2>
          </div>
          <div class="paragraphe">
            <p>
              Notre
              <strong
                >service de taxi: pour les transferts de gares et
                d'aéroports</strong
              >
              est une <strong>solution de transport</strong> pratique et fiable
              pour les voyageurs qui ont besoin d'arriver à leur destination en
              temps et en heure. Nous avons une flotte de
              <strong>taxis</strong> modernes et confortables qui sont équipés
              pour répondre à tous vos besoins de transport sur le secteur de
              <strong>Les Mureaux</strong>. Nous proposons également des tarifs
              compétitifs pour rendre nos services accessibles à tous.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-right">
          <div class="image">
            <img
              src="@/assets/pictures/terminal.webp"
              alt="photo-aeroport"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="text_right">
      <div class="row spacing flex-lg-row-reverse justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-right">
          <div class="title">
            <h2 class="exeption-r">Trajets quotidiens</h2>
          </div>
          <div class="paragraphe">
            <p class="exeption-r">
              <strong>Ariane-Taxis</strong> est là pour vous ! Nos chauffeurs
              expérimentés vous garantissent un trajet sans encombre, avec
              confort et sécurité. Que ce soit pour vous rendre à l'aéroport, à
              la gare ou simplement d'un point A à un point B, notre
              <strong>service de taxi</strong> est là pour répondre à tous vos
              besoins de transport, 24 heures sur 24, 7 jours sur 7. Voyagez en
              toute tranquillité avec <strong>Ariane-Taxis</strong>, votre
              partenaire de confiance pour tous vos déplacements.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-left">
          <div class="image">
            <img
              src="@/assets/pictures/taxi-course.webp"
              alt="taxi-in-paris"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="text_left touristique">
      <div class="row justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-left">
          <div class="title">
            <h2>Circuit Touristique</h2>
          </div>
          <div class="paragraphe">
            <p>
              Nous proposons également des
              <strong>circuits touristiques</strong> pour les personnes qui
              souhaitent <strong>découvrir les sites touristiques</strong> et
              d'intérêt de <strong>Paris</strong> et de
              <strong>l'Île-de-France</strong>.
              <strong>Les taxis</strong> ariane connaissent parfaitement les
              meilleurs <strong>endroits à visiter</strong> et sont à votre
              disposition pour vous aider à planifier votre itinéraire. Nous
              offrons une <strong>solution de transport</strong> pratique et
              flexible pour que vous puissiez découvrir
              <strong>Paris en taxi</strong> à votre rythme.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-right">
          <div class="image">
            <img
              src="@/assets/pictures/pyramide-louvre.webp"
              alt="museum-paris-louvre"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    // Effet d'apparition
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
        }
      });
    });

    const elementsRight = document.querySelectorAll(".slide-right");
    elementsRight.forEach((element) => {
      observer.observe(element);
    });

    const elementsLeft = document.querySelectorAll(".slide-left");
    elementsLeft.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style lang="scss" scoped>
//
.spacing {
  @media screen and (min-width: 992px) {
    margin: 120px 0px;
  }
}
.exeption-r {
  @media screen and (min-width: 992px) {
    text-align: left !important;
  }
}
.content {
  max-width: 1400px;
  overflow: hidden;
  margin: auto;
  @media screen and (max-height: 500px) {
    margin-top: 150px;
  }
  @media screen and (min-width: 576px) {
    width: 100%;
  }
}
section {
  margin-top: 70px;
}
h1 {
  color: $color-primary;
  margin-top: 100px;
  margin-bottom: 180px;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 2.2em;
  @media screen and (max-width: 992px) {
    font-size: 1.8em;
    margin-top: 70px;
    margin-bottom: 90px;
    padding: 0px 15px;
  }
}
h2 {
  margin-bottom: 30px;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #000000aa;
  text-align: center;
  @media screen and (min-width: 992px) {
    text-align: right;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 15px;
  }
}
p {
  text-align: start;
  padding: 0px 0px;
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    text-align: end;
    padding: 0px 10px;
  }
}
.image {
  max-width: 100%;
  padding: 0px 3px 3px 0px;
  img {
    width: 100%;
    height: 290px;
  }
  @media screen and (max-width: 992px) {
    margin-top: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

// Gare
.text_left {
  .img_content {
    padding: 20px;
    .image {
      background-color: $color-primary;
      padding: 0px 0px 3px 3px;
      img {
        position: relative;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}
.text_right {
  .img_content {
    padding: 20px;
    .image {
      background-color: #ebd409;
      img {
        position: relative;
        bottom: 30px;
        left: 30px;
      }
    }
  }
}

// Small ecran
@media screen and (max-width: 576px) {
  .text_left,
  .text_right {
    .img_content {
      padding: 0;
      .image {
        width: 100%;
        border: none;
        img {
          width: 100%;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}

// Animation Apparation gauche
.slide-left {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-left.active {
  transform: translateX(0%);
  opacity: 1;
}

// Animation Apparation droite
.slide-right {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-right.active {
  transform: translateX(0%);
  opacity: 1;
}
</style>
