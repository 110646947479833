<template>
  <div class="content">
    <h2 class="text-center slide-left">{{ town }}:<br />Réserver un taxi</h2>
    <section class="text_left gare">
      <div class="row justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-left">
          <div class="title">
            <h2>{{ town }}: Un taxi vers les Gares & Aéroports</h2>
          </div>
          <div class="paragraphe">
            <p>
              Vous avez besoin d'un <strong>taxi à {{ town }}</strong> pour
              rejoindre une gare ou un aéroport ? Ariane
              <strong>Taxis</strong> est votre partenaire de confiance pour des
              transferts rapides et fiables en <strong>taxi à {{ town }}</strong
              >. Nous comprenons l'importance de la ponctualité lors de vos
              déplacements vers les <strong>gares et aéroports</strong>. Nos
              chauffeurs expérimentés sont toujours à l'heure et vous assurent
              un trajet confortable et sans stress en
              <strong>taxi à {{ town }}</strong
              >..
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-right">
          <div class="image">
            <img
              src="@/assets/pictures/terminal.webp"
              alt="photo-aeroport"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="text_right">
      <div class="row spacing flex-lg-row-reverse justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-right">
          <div class="title">
            <h2 class="exeption-r">
              {{ town }}: Un taxi pour vos Trajets quotidiens
            </h2>
          </div>
          <div class="paragraphe">
            <p class="exeption-r">
              Pour vos trajets quotidiens, faites confiance à Ariane
              <strong>Taxis</strong>. Que ce soit pour aller au travail, à
              l'école ou pour toute autre activité régulière, nous vous offrons
              un service de <strong>taxi à {{ town }}</strong> sûr et
              confortable. Nos tarifs pour les
              <strong>taxis à {{ town }}</strong> sont compétitifs et
              transparents. Profitez de la tranquillité d'esprit avec notre
              service de <strong>taxi à {{ town }}</strong> pour tous vos
              besoins de déplacement quotidien.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-left">
          <div class="image">
            <img
              src="@/assets/pictures/taxi-course.webp"
              alt="taxi-in-paris"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="text_left touristique">
      <div class="row justify-content-around">
        <div class="col-11 col-sm-10 col-lg-5 text slide-left">
          <div class="title">
            <h2>{{ town }}: Circuit Touristique en taxi</h2>
          </div>
          <div class="paragraphe">
            <p>
              Découvrez les trésors de {{ town }} et ses environs avec Ariane
              <strong>Taxis</strong>. Nous proposons des circuits touristiques
              personnalisés en <strong>taxi à {{ town }}</strong
              >, vous permettant d'explorer les sites incontournables à votre
              rythme. Nos chauffeurs expérimentés connaissent parfaitement la
              région et sauront vous guider pour une expérience mémorable en
              <strong>taxi à {{ town }}</strong
              >. Profitez de nos services de
              <strong>taxi à {{ town }}</strong> pour une découverte inoubliable
              et confortable.
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-10 col-lg-5 img_content slide-right">
          <div class="image">
            <img
              src="@/assets/pictures/pyramide-louvre.webp"
              alt="museum-paris-louvre"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServiceTaxi",
  props: {
    town: String,
  },
  mounted() {
    // Effet d'apparition
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
        }
      });
    });

    const elementsRight = document.querySelectorAll(".slide-right");
    elementsRight.forEach((element) => {
      observer.observe(element);
    });

    const elementsLeft = document.querySelectorAll(".slide-left");
    elementsLeft.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style lang="scss" scoped>
//
.spacing {
  @media screen and (min-width: 992px) {
    margin: 120px 0px;
  }
}
.exeption-r {
  @media screen and (min-width: 992px) {
    text-align: left !important;
  }
}
.content {
  max-width: 1400px;
  overflow: hidden;
  margin: auto;
  padding: 30px 0px;
  @media screen and (max-height: 500px) {
    padding: 100px 0px;
    margin-top: 150px;
  }
  @media screen and (min-width: 576px) {
    width: 100%;
  }
}
section {
  margin-top: 70px;
}
h1 {
  color: $color-primary;
  margin-top: 100px;
  margin-bottom: 180px;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 2.2em;
  @media screen and (max-width: 992px) {
    font-size: 1.8em;
    margin-top: 70px;
    margin-bottom: 90px;
    padding: 0px 15px;
  }
}
h2 {
  margin-bottom: 30px;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #000000aa;
  text-align: center;
  @media screen and (min-width: 992px) {
    text-align: right;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 15px;
  }
}
p {
  text-align: start;
  padding: 0px 0px;
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    text-align: end;
    padding: 0px 10px;
  }
}
.image {
  max-width: 100%;
  padding: 0px 3px 3px 0px;
  img {
    width: 100%;
    height: 290px;
  }
  @media screen and (max-width: 992px) {
    margin-top: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

// Gare
.text_left {
  .img_content {
    padding: 20px;
    .image {
      background-color: $color-primary;
      padding: 0px 0px 3px 3px;
      img {
        position: relative;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}
.text_right {
  .img_content {
    padding: 20px;
    .image {
      background-color: #ebd409;
      img {
        position: relative;
        bottom: 30px;
        left: 30px;
      }
    }
  }
}

// Small ecran
@media screen and (max-width: 576px) {
  .text_left,
  .text_right {
    .img_content {
      padding: 0;
      .image {
        width: 100%;
        border: none;
        img {
          width: 100%;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}

// Animation Apparation gauche
.slide-left {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-left.active {
  transform: translateX(0%);
  opacity: 1;
}

// Animation Apparation droite
.slide-right {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-right.active {
  transform: translateX(0%);
  opacity: 1;
}
</style>
