<template>
  <section>
    <div class="cartes row">
      <div class="titleRow">
        <h2>Votre Taxi depuis {{ town }}</h2>
      </div>
      <div class="content row justify-content-evenly">
        <div class="col-8 col-md-3 cart">
          <img
            src="@/assets/pictures/aeroport.webp"
            alt="taxi-park-street"
            loading="lazy"
          />
          <h3 class="mb-3">Taxi<br />Aéroport</h3>
          <p>
            Ariane <strong>Taxis</strong> vous offre des
            <strong>transferts aéroportuaires</strong> en
            <strong>taxi à {{ town }}</strong> vers les principaux aéroports de
            la région.
          </p>
        </div>
        <div class="col-8 col-md-3 cart">
          <img
            src="@/assets/pictures/gare.webp"
            alt="gare-montparnasse"
            loading="lazy"
          />
          <h3 class="mb-3 mx-auto">Taxi<br />Gare Paris</h3>
          <p>
            un <strong>Service de taxi</strong> pratique et fiable pour tous les
            voyageurs qui ont besoin d'un
            <strong>taxi vers les gares</strong> de la capitale depuis
            <strong>{{ town }}</strong
            >.
          </p>
        </div>
        <div class="col-8 col-md-3 cart">
          <img
            src="@/assets/pictures/acceuilHopital.webp"
            alt="taxi-front-hopital"
            loading="lazy"
          />
          <h3 class="mb-3">Taxi<br />Médical</h3>
          <p>
            Pour vos déplacements médicaux en
            <strong>taxi conventionné sur {{ town }}</strong> pour un
            <strong>transport vers les hôpitaux et les cliniques</strong> de la
            région.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CardTaxi",
  props: {
    town: String,
  },
};
</script>

<style lang="scss" scoped>
section {
  background-color: $color-primary;
  padding-bottom: 20px;
  & h2 {
    color: #ebd409;
    text-align: center;
    font-size: 2.5em;
    margin: 20px 25px 50px;
    .shadow__btn:hover {
      box-shadow: 0 0 5px #e9e90a, 0 0 25px #e9e90a, 0 0 50px #e9e90a,
        0 0 100px #e9e90a;
      & a:hover {
        text-decoration: none;
      }
    }
    @media screen and (min-width: 992px) {
      margin-bottom: 25px;
    }
  }
}

.text {
  position: absolute;
  top: 100px;
  left: auto;
  @media screen and (min-width: 992px) {
    position: absolute;
    top: 120px;
    left: auto;
  }
}

// Cart
.cartes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  margin-top: 0px;
}
.cart {
  width: 18rem;
  height: 22rem;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  color: $color-primary;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  margin: 20px 10px;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.2s ease-out;
  }
  h3 {
    position: absolute;
    inset: 250px auto 0px 10px;
    margin: 0;
    color: #ebd409;
    transition: inset 0.3s 0.3s ease-out;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px #000000aa;
  }

  p {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity 0.3s ease-out;
    inset: auto auto 80px 30px;
    color: white;
    text-align: start;
    @media screen and (min-width: 992px) {
      text-align: start;
    }
  }

  &:hover h3 {
    inset: auto auto 220px 30px;
    transition: inset 0.3s ease-out;
  }

  &:hover p {
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in;
  }

  &:hover img {
    transition: all 0.3s ease-in;
    opacity: 0.4;
    filter: blur(4px);
  }
}

// A partirdes tablette
@media screen and (max-width: 820px) {
  .cart {
    img {
      filter: brightness(60%);
    }
    h3 {
      position: absolute;
      inset: auto auto 220px 30px;
    }

    p {
      opacity: 1;
    }
  }
}
</style>
