<template>
  <div class="container-fluid arianne">
    <h2>Ariane Taxis à {{ town }}</h2>
    <p class="col-10">
      Ariane <strong>Taxis</strong> est née le 6 juin 2005 de l'association de
      <strong>taxis</strong> dans le secteur de
      <strong>{{ town }}</strong> souhaitant unir leurs forces et offrir un
      service de qualité. Cette initiative a été guidée par la volonté
      d'assembler une équipe de professionnels chevronnés partageant une vision
      moderne du service client. Grâce à son succès fulgurant, Ariane
      <strong>Taxis</strong> compte désormais une flotte de dix
      <strong>taxis à {{ town }}</strong> et collabore avec un réseau de
      chauffeurs expérimentés, solidement établis.
    </p>
  </div>
  <div class="container-fluid">
    <div class="button">
      <a href="/"><button>Réserver votre course</button></a>
    </div>
    <div class="row orderRide">
      <div class="imageRide col-10 col-lg-5">
        <img
          src="@/assets/undraw/getaride.webp"
          alt="order-taxi-seine"
          loading="lazy"
          class="img-fluid"
        />
      </div>
      <div class="textRide col-10 col-lg-5">
        <h2>{{ town }}, un taxi en quelques clics.</h2>
        <p>
          Ariane <strong>Taxis à {{ town }}</strong> met à votre disposition un
          service de réservation en ligne facile et rapide. Avec quelques clics,
          vous pouvez <strong>réserver un taxi sur {{ town }}</strong> ou
          secteurs limitrophes pour vos trajets ponctuels et réguliers en
          <strong>taxi</strong>. Notre plateforme est disponible 24/7 et vous
          permet de saisir vos coordonnées ainsi que les informations sur votre
          trajet. Notre système de
          <strong>réservation de taxi sur {{ town }}</strong> est sécurisé et
          notre service client est disponible pour répondre à toutes vos
          questions. Nous sommes fiers de vous offrir un service de qualité avec
          des chauffeurs expérimentés et des véhicules bien entretenus pour tout
          vos trajets depuis et vers <strong>{{ town }}</strong> en taxi.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArianeTaxi",
  props: {
    town: String,
  },
};
</script>

<style lang="scss" scoped>
.arianne {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 20px 0px;
  & h2 {
    font-size: 3em;
    text-align: center;
  }
  & p {
    text-align: center;
    margin-top: 20px;
  }
  @media screen and (min-width: 992px) {
    padding: 70px 0px 0px;
    & p {
      text-align: center;
    }
  }
}
.orderRide {
  display: flex;
  justify-content: center;
  padding: 40px 20px 100px 20px;
  text-align: justify;
  & h2 {
    margin-bottom: 20px;
    text-align: start;
  }
  & p {
    text-align: start;
  }
  @media screen and (min-width: 992px) {
    padding: 80px 2px 40px 2px;
    justify-content: space-around;
    & h2 {
      text-align: start;
    }
  }
}
.button {
  margin: 70px 10px 0px 10px;
  text-align: center;
  @media screen and (min-width: 992px) {
    margin: 100px 10px 0px 10px;
  }
  button {
    padding: 5px 10px;
    font-size: 1.6em;
    text-transform: uppercase;
    color: $color-secondary;
    background-color: $color-primary;
    border: 2px outset $color-secondary;
    border-radius: 10px;
    transition: all 0.3s;
    &:hover {
      transform: scale(0.99);
      transition: all 0.3s;
      background-color: $color-darker;
      color: #ebd409;
    }
  }
}
</style>
