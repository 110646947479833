<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Ariane-taxis` : `Ariane-taxis`
    }}</template>
  </metainfo>
  <div>
    <NavBar />
  </div>
  <div class="router">
    <router-view />
  </div>
  <div>
    <FooterBase />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import NavBar from "./components/NavBar.vue";
import FooterBase from "./components/FooterBase.vue";

export default {
  components: { NavBar, FooterBase },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "fr" },
      description: [
        {
          content:
            "Besoin d'un taxi sur Les Mureaux ou dans les Yvelines ? Ariane-taxis une entreprise de taxi professionnelle et fiable qui propose des services de transport locaux et longue distance. Nos chauffeurs expérimentés vous conduiront en toute sécurité et à temps à votre destination, que vous ayez besoin d'un taxi pour vos trajets quotidiens ou pour des transferts vers les aéroports de Paris et d'Ile-de-France. Contactez-nous dès maintenant pour réserver votre taxi.",
        },
      ],
    });
  },
};
</script>

<style lang="scss">
//
</style>
