<template>
  <section class="slide-top">
    <div class="text container">
      <h2>Besoin d'appeler un taxi à {{ town }} ?</h2>
      <p>
        Ariane <strong>Taxis</strong> est à votre écoute nous répondront à
        toutes vos questions et demandes de renseignements concernant la
        <strong>réservation d'un taxi à {{ town }}</strong
        >.
      </p>
    </div>
    <a href="/taxi-les-mureaux-contact">
      <div class="button">
        <button>Contactez-nous</button>
      </div>
    </a>
  </section>
</template>

<script>
export default {
  props: {
    town: String,
  },
  mounted() {
    // Effet d'apparition
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
        }
      });
    });

    const elementsTop = document.querySelectorAll(".slide-top");
    elementsTop.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 80px 0px;
  background-color: $color-primary;
  padding: 70px 10px;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  p {
    color: $color-secondary;
    padding: 0px 10px;
  }
  h2 {
    font-size: 1.7em;
    text-align: center;
    margin-bottom: 10px;
    color: $color-secondary;
    letter-spacing: 1px;
    font-weight: 700;
    text-shadow: 1px 1px 15px #000;
  }
}
.button {
  text-align: center;
  button {
    padding: 10px;
    color: $color-primary;
    border: 2px solid $color-secondary;
    background: $color-secondary;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 10px;
    box-shadow: 3px 3px 10px black;
    transition: all 0.4s ease-in-out;
    &:hover {
      box-shadow: 1px 1px 7px black;
      background-color: #ebd409;
      border: 2px solid $color-primary;
      color: $color-text;
      transition: all 0.4s ease-in-out;
    }
    &:active {
      transform: scale(0.98);
    }
  }
}

.slide-top {
  transform: translateY(30%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-top.active {
  transform: translateY(0%);
  opacity: 1;
}
</style>
