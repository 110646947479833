<template>
  <div class="container-fluid">
    <div class="video-header">
      <video
        playsinline="playsinline"
        autoplay="autoplay"
        muted="muted"
        loop="loop"
      >
        <source src="@/assets/video/ariane-movies.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="wrapper d-flex justify-content-around align-items-start">
      <FormRide welcome="Bienvenue" />
      <SpeechHeader />
    </div>
  </div>
</template>

<script>
import FormRide from "../partial/FormRide.vue";
import SpeechHeader from "../partial/SpeechHeader.vue";

export default { components: { FormRide, SpeechHeader } };
</script>

<style lang="scss" scoped>
.video-header {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  & video {
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -70%);
    min-width: 100%;
    min-height: 100%;
  }
}

.wrapper {
  position: absolute;
  top: 25%;
  width: 100%;
  height: 80vh;
  padding: 10px 20px;
}
</style>
