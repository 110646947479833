<template>
  <div class="mentions-legales container my-5 py-5">
    <h2 class="mb-4">Mentions légales :</h2>
    <p>
      Le site internet ariane-taxis.com est Géré par Ariane-Taxis, groupement de
      taxis professionnels opérant sur le secteur des yvelines.
    </p>
    <p>Adresse de contact : contact@ariane-taxis.com</p>
    <p>
      Le site internet a été réalisé par l'agence Digital Malaweb, immatriculée
      au Registre du Commerce et des Sociétés de Metz sous le numéro 8931 3539
      2000 15.<br />
      site internet : <a href="https://www.malaweb.fr">Malaweb.fr</a>
    </p>
    <p>Hébergement :</p>
    <p>
      OVH, 2 rue Kellermann - 59100 Roubaix - France, immatriculée au Registre
      du Commerce et des Sociétés de Lille Métropole sous le numéro 424 761 419
      00045.
    </p>
    <h3 class="mt-5 mb-4">Protection des données personnelles :</h3>
    <p>
      Ariane-taxis s'engage à respecter la législation française et européenne
      en matière de protection des données personnelles, notamment le Règlement
      Général sur la Protection des Données (RGPD).
    </p>
    <p>
      Les données personnelles collectées par Ariane-taxis sont celles que vous
      fournissez lors de la réservation ou du formulaire de contact (nom,
      prénom, adresse e-mail, numéro de téléphone) ainsi que les informations
      relatives à la course (lieu de prise en charge, destination, horaire).
    </p>
    <p>
      Ces données sont conservées pendant la durée nécessaire à la réalisation
      de la prestation de transport ou à la gestion de votre demande. Une fois
      la prestation effectuée ou la demande traitée, les données sont supprimées
      de nos systèmes.
    </p>
    <p>
      Ariane-taxis.com utilise Google Analytics pour mesurer le trafic sur son
      site internet, mais ne partage jamais les informations ni ne les utilise à
      des fins publicitaires.
    </p>
    <p>
      Vous disposez d'un droit d'accès, de rectification, de suppression et de
      portabilité de vos données personnelles. Vous pouvez exercer ces droits en
      contactant Ariane-taxis à l'adresse de contact indiquée ci-dessus.
    </p>
    <h3 class="mt-5 mb-4">Copyright :</h3>
    <p>
      Copyright © Ariane-taxis:<br />
      Tous droits réservés. Toute reproduction totale ou partielle du contenu de
      ce site, par quelque moyen que ce soit, sans autorisation préalable de
      Malaweb est interdite et constituerait une contrefaçon sanctionnée par les
      articles L335-2 et suivants du Code de la Propriété Intellectuelle.
    </p>
    <h3 class="mt-5 mb-4">Loi applicable et juridiction compétente :</h3>
    <p>
      Les présentes mentions légales sont régies par la loi française. Tout
      litige relatif à l'interprétation ou à l'exécution des présentes mentions
      légales sera de la compétence exclusive des tribunaux français.
    </p>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
export default {
  name: "MentionLegale",
  setup() {
    useMeta({
      title: "Mentions Légales",
      description: [
        {
          content:
            "Ariane-taxis s'engage à respecter la législation française et européenne en matière de protection des données personnelles, notamment le Règlement Général sur la Protection des Données (RGPD).",
        },
      ],
    });
  },
};
</script>

<style lang="sss" scoped></style>
