<template>
  <section>
    <div class="d-flex justify-content-center mb-2 slide-left">
      <h2 class="col-10">Votre taxi à Les Mureaux et secteurs limitrophes</h2>
    </div>
    <div class="presentation row justify-content-around">
      <div class="container p-2 col-12 col-lg-4 text-center slide-left">
        <img
          src="@/assets/pictures/carte-taxi-yvelines.webp"
          alt="map-yvelynes"
          loading="lazy"
          class="img-fluid"
          width="450"
        />
      </div>
      <div class="text col-11 col-md-10 col-lg-6 slide-right">
        <p>
          Si vous recherchez un service de
          <strong>taxi à Les Mureaux</strong>, ne cherchez pas plus loin que
          <strong>Ariane-taxis</strong>. Nous offrons une gamme complète de
          services pour répondre à tous vos besoins. Notre flotte de véhicules
          modernes et confortables est disponible 7 jours sur 7 pour vous
          emmener vers les <strong>gares</strong> ou les
          <strong>aéroports</strong> de votre choix. Que vous ayez besoin d'un
          transfert de la <strong>gare de Les Mureaux</strong> à l'<strong
            >aéroport de Roissy-Charles-de-Gaulle</strong
          >
          ou d'un transport vers la <strong>gare de Montparnasse</strong>, nous
          sommes là pour vous aider. Notre service ne se limite pas seulement à
          <strong>Les Mureaux</strong>. Nous nous déplaçons sur
          <strong>Les Mureaux</strong> et secteurs limitrophes pour répondre à
          tous vos besoins. N'hésitez pas à nous contacter pour
          <strong>réserver votre taxi</strong> ou pour discuter de vos besoins
          en matière de transport. Nous sommes là pour vous aider à vous
          déplacer en toute sécurité et en toute confiance dans toute
          <strong>vos trajet à bord de nos taxis</strong>.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    // Effet d'apparition
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
        }
      });
    });

    const elementsRight = document.querySelectorAll(".slide-right");
    elementsRight.forEach((element) => {
      observer.observe(element);
    });

    const elementsLeft = document.querySelectorAll(".slide-left");
    elementsLeft.forEach((element) => {
      observer.observe(element);
    });
  },
};
</script>

<style lang="scss" scoped>
//
section {
  margin: 100px 0px;
  @media screen and (min-width: 992px) {
    margin: 180px 0px;
  }
  width: 100%;
  overflow: hidden;
}
h2 {
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.2em;
  text-shadow: 1px 1px 2px #000000aa;
  @media screen and (max-width: 640px) {
    font-size: 2em;
  }
  @media screen and (min-width: 992px) {
    margin-bottom: 100px;
  }
}

.icone {
  background-color: $color-primary;
  img {
    width: 100%;
    position: relative;
    bottom: 30px;
    left: 30px;
  }
  @media screen and (max-width: 992px) {
    width: 80%;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 640px) {
    border: none;
    width: 100%;
    img {
      width: 100%;
      min-height: 250px;
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
}

p {
  line-height: 27px;
  text-align: start;
  font-size: 1em;
  padding: 40px 10px;
  @media screen and (min-width: 992px) {
    position: relative;
    bottom: 15px;
    font-size: 1.1em;
    text-align: start;
    line-height: 31px;
    letter-spacing: 1px;
  }
}

// Animation Apparation gauche
.slide-left {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-left.active {
  transform: translateX(0%);
  opacity: 1;
}

// Animation Apparation droite
.slide-right {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}
.slide-right.active {
  transform: translateX(0%);
  opacity: 1;
}
</style>
