<template>
  <div class="form col-4">
    <div class="title">{{ welcome }}</div>
    <div class="subtitle">Où allons-nous ?</div>
    <div class="input-container ic1">
      <input
        id="start"
        class="input"
        type="text"
        placeholder=" "
        maxlength="70"
        required
        v-model="depart"
        @input="autocompleteDepart"
        :readonly="watchInput"
      />
      <div class="cut"></div>
      <label for="start" class="placeholder">Départ</label>
      <ul class="autocomplete-options" v-if="startAutocompleteOptions.length">
        <li
          v-for="(option, index) in startAutocompleteOptions"
          :key="index"
          @click="selectDepartOption(option)"
        >
          {{ option.properties.label }}
        </li>
      </ul>
    </div>
    <div class="input-container ic2">
      <input
        id="end"
        class="input"
        type="text"
        placeholder=" "
        maxlength="70"
        required
        v-model="destination"
        @input="autocompleteDestination"
        :readonly="watchInput"
      />
      <div class="cut"></div>
      <label for="end" class="placeholder">Arrivée</label>
      <ul class="autocomplete-options" v-if="endAutocompleteOptions.length">
        <li
          v-for="(option, index) in endAutocompleteOptions"
          :key="index"
          @click="selectDestinationOption(option)"
        >
          {{ option.properties.label }}
        </li>
      </ul>
    </div>
    <div class="input-container ic2">
      <input
        id="price"
        class="input"
        type="tel"
        placeholder=" "
        :value="price"
        @input="price"
        readonly
      />
      <div class="cut cut-short"></div>
      <label for="price" class="placeholder">Prix</label>
    </div>
    <span v-if="orderBtn">
      <button type="text" class="submit submit__order" @click="orderModal">
        Réserver la course
      </button>
    </span>
    <span v-else>
      <button type="text" class="submit submit__calcul" @click="calculatePrice">
        Calculer
      </button>
    </span>
  </div>
  <span v-if="order">
    <OrderRide
      @closeOrder="closeOrder"
      @openModalConfirm="openModalConfirm"
      :price="this.price"
      :distance="this.distance"
      :duration="this.duration"
      :depart="this.depart"
      :destination="this.destination"
    />
  </span>
  <span v-if="modalConfirm">
    <ConfirmModal
      @closeModalConfirm="closeModalConfirm"
      title="Merci de votre confiance"
      message="Vous allez être recontactés par un de nos chauffeurs"
      btnExit="À tout de suite"
    />
  </span>
</template>

<script>
import axios from "axios";
import OrderRide from "@/components/modals/RideOrder.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  components: { OrderRide, ConfirmModal },
  props: {
    welcome: String,
  },
  data() {
    return {
      depart: "",
      destination: "",
      duration: "",
      distance: "",
      price: "",
      startAutocompleteOptions: [],
      endAutocompleteOptions: [],
      orderBtn: false,
      watchInput: false,
      order: false,
      orderValidation: false,
      modalConfirm: false,
    };
  },
  methods: {
    async autocompleteDepart() {
      if (this.depart.length >= 3) {
        axios
          .get(
            `https://api-adresse.data.gouv.fr/search/?q=${this.depart}&limit=5`
          )
          .then((response) => {
            this.startAutocompleteOptions = response.data.features;
          });
      } else {
        this.startAutocompleteOptions = [];
      }
    },
    async autocompleteDestination() {
      if (this.destination.length >= 3) {
        axios
          .get(
            `https://api-adresse.data.gouv.fr/search/?q=${this.destination}&limit=5`
          )
          .then((response) => {
            this.endAutocompleteOptions = response.data.features;
          });
      } else {
        this.endAutocompleteOptions = [];
      }
    },

    async calculatePrice() {
      // Appel à l'API Data Gouv pour récupérer les coordonnées de départ et d'arrivée
      const departResponse = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${this.depart}&limit=1`
      );
      const departCoordinates =
        departResponse.data.features[0].geometry.coordinates;

      const destinationResponse = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${this.destination}&limit=1`
      );
      const destinationCoordinates =
        destinationResponse.data.features[0].geometry.coordinates;

      // Reformattage des coordonnées au format attendu par l'API OpenRouteService
      const coordinates = [departCoordinates, destinationCoordinates]
        .flat()
        .map((c) => c.toFixed(5));
      const start = `${coordinates[0]},${coordinates[1]}`;
      const end = `${coordinates[2]},${coordinates[3]}`;

      // Appel à l'API OpenRouteService pour récupérer la distance et le temps de trajet
      const response = await axios.get(
        `https://api.openrouteservice.org/v2/directions/driving-car?api_key=5b3ce3597851110001cf6248f39db9cb3f3a4b7dabec40870bfa309f&start=${start}&end=${end}&radius=500`
      );
      const distance =
        response.data.features[0].properties.segments[0].distance;
      this.distance = distance / 1000;
      this.distance = this.distance.toFixed(0);
      const duration =
        response.data.features[0].properties.segments[0].duration;
      this.duration = duration / 60;
      this.duration = this.duration.toFixed(0);

      // Calcul du prix
      const price = (distance / 1000) * 2;

      // Affichage du résultat dans le champ de prix
      this.price = price.toFixed(2);
      this.orderBtn = true;
      this.watchInput = true;
    },

    selectDepartOption(option) {
      this.depart = option.properties.label;
      this.startAutocompleteOptions = [];
    },
    selectDestinationOption(option) {
      this.destination = option.properties.label;
      this.endAutocompleteOptions = [];
    },
    setPrice(event) {
      this.price = event.target.value;
    },

    orderModal() {
      this.order = true;
      this.orderBtn = false;
      this.watchInput = false;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    closeOrder() {
      this.order = false;
    },
    openModalConfirm() {
      this.modalConfirm = true;
    },
    closeModalConfirm() {
      this.modalConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  background-color: $color-darker;
  border-radius: 20px;
  box-sizing: border-box;
  height: 460px;
  padding: 10px 20px;
  width: 100%;
  max-width: 320px;
  @media screen and (max-width: 992px) {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.title {
  color: #ebd409;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 10px;
}

.subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #303245;
  @media screen and (max-width: 992px) {
    background-color: #303245b7;
  }
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: $color-darker;
  @media screen and (max-width: 992px) {
    background-color: rgba(0, 0, 0, 0);
  }
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 70px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
  background-color: $color-primary;
}

.placeholder {
  color: #ffffffb4;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #ebd409;
}

.input:focus ~ .placeholder {
  color: #ebd409;
}

.submit {
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  // outline: 0;
  text-align: center;
  width: 100%;
  &__calcul {
    color: #eee;
    background-color: $color-primary;
  }
  &__order {
    color: $color-primary;
    border: 1px solid $color-primary;
    background-color: #ebd409;
  }
}

.submit:active {
  background-color: $color-darker;
  color: $color-primary;
}
.autocomplete-options {
  color: $color-primary;
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  li {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
</style>
