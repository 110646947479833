<template>
  <div class="content row justify-content-evenly">
    <section class="col-10 col-lg-5">
      <h2>Taxi Les Mureaux:</h2>
      <h3 class="schedules"><span>Un service</span> <strong>7/7J</strong></h3>
      <h3>Réservez un taxi sur Les Mureaux ou secteurs limitrophes.</h3>
      <p>
        Pour tous vos trajets en
        <strong>taxi à Les Mureaux</strong>:<br />Personnels, professionnels ou
        médicaux, nous vous proposons un service de qualité.
      </p>
      <p>
        Lors de votre départ ou de votre arrivée, nous sommes en mesure
        d’assurer votre transport sur le secteur de
        <strong>Les Mureaux</strong> vers et à partir de
        <strong>toutes les gares et aéroport</strong> de votre destination. Si
        vous voyagez avec des enfants, nous disposons dans nos
        <strong>taxis</strong> de <strong>sièges bébé</strong> et de
        <strong>rehausseurs</strong> pour assurer leur confort et leur sécurité.
      </p>
      <p>
        N'hésitez plus, si vous souhaitez réserver un
        <strong>taxi sur Les Mureaux</strong> ou secteur limitrophe
        contactez-nous via le formulaire de contact ou notre module de
        <a href="https://www.ariane-taxis.com/"
          ><strong>réservation pour un trajet en taxi</strong></a
        >
      </p>
      <div class="image">
        <img
          src="@/assets/undraw/rocket.webp"
          alt="taxi-drawing-roadMap"
          loading="lazy"
        />
      </div>
      <div class="trait"></div>
    </section>
    <section class="col-10 col-lg-5 formulaire">
      <h2>Formulaire de contact</h2>
      <form ref="formData" @submit.prevent="sendEmail">
        <div class="form">
          <div class="col">
            <input
              type="text"
              placeholder="Votre Nom"
              name="lastname"
              maxlength="30"
              required
            />
          </div>
          <div class="col">
            <input
              type="text"
              placeholder="Votre Prénom"
              name="firstname"
              required
              maxlength="30"
            />
          </div>
          <div class="col">
            <input
              type="tel"
              placeholder="Votre Téléphone"
              name="phone"
              required
              pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
            />
          </div>
          <div class="col">
            <input
              type="email"
              placeholder="Votre email"
              name="email"
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            />
          </div>
          <div class="col">
            <textarea
              name="message"
              placeholder="Votre message"
              rows="5"
              pattern="^[^/<>\n]*$"
              maxlength="450"
              required
            ></textarea>
          </div>
          <span v-if="this.isCaptchaVisible">
            <vue-hcaptcha
              sitekey="11bf6d60-c765-4d69-8c1f-d8033f9bd40c"
              @verify="onVerify"
              @error="onError"
            ></vue-hcaptcha>
          </span>
          <button type="submit">Envoyer</button>
        </div>
      </form>
      <span v-if="this.modalConfirm">
        <ConfirmModal
          @closeModalConfirm="closeModalConfirm"
          title="Merci de votre intérêt"
          message="Vous allez être recontactés dans les plus brefs délais"
          btnExit="À bientôt"
        />
      </span>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: { VueHcaptcha, ConfirmModal },
  data() {
    return {
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendContactEmail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendContactEmail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 100px 0px 130px 0px;
}
@media screen and (max-width: 992px) {
  .content {
    margin: 80px 0;
  }
  .trait {
    margin: 60px 20vw;
    width: 40vw;
    height: 1px;
    background-color: $color-primary;
  }
}
.image {
  width: 100%;
  margin-top: 80px;
  img {
    width: 100%;
    margin-bottom: 50px;
  }
}
h2,
.schedules {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 1.8em;
  text-shadow: 1px 1px 2px #000000aa;
  span {
    color: black;
  }
}
h3 {
  color: black;
  font-size: 1.1em;
  margin-bottom: 20px;
  font-weight: bold;
}
p {
  margin-bottom: 10px;
}
img {
  width: 20px;
  margin-right: 10px;
}
.formulaire {
  h2 {
    text-align: center;
    text-transform: uppercase;
  }
}
.form {
  background-color: $color-secondary;
  padding: 40px 20px;
  border-radius: 5px;
  text-align: center;
  input,
  textarea {
    margin-bottom: 20px;
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    &:focus {
      box-shadow: 1px 1px 3px black;
    }
    &::placeholder {
      color: $color-primary-dark;
    }
  }
  button {
    border: none;
    padding: 15px 50px;
    border-radius: 100px;
    font-size: 1.2em;
    letter-spacing: 1px;
    background-color: $color-primary;
    color: white;
    opacity: 0.8;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.884);
    &:hover {
      opacity: 1;
    }
    &:active {
      transform: scale(0.98);
      box-shadow: 1px 1px 3px black;
    }
  }
}
.g-recaptcha {
  margin-bottom: 30px;
}
</style>
